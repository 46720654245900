
export const MenuItems =[{
    title:'Home',
    url:'/Home',
    cName:'nav-links'
},
{
    title:'Services',
    url:'/Services',
    cName:'nav-links'
},

{
    title:'Contact Us',
    url:'/Contact',
    cName:'nav-links'
},
]