import '../../App.css'
import Hero from "../Hero.js"
import Card from "../Card/Card.js"
import Footer from '../Footer/Footer.js'






function Home(){
    return(
        <>
        <Hero></Hero>
        <Card/>
        <Footer/>
        </>
    );
}
export default Home;