import '../../App.css'
import Footer from '../Footer/Footer.js'
import ContactBody from '../ServicesPage/ContactBody.js'






function Contact(){
    return(
        <>
        <ContactBody/>
        <Footer/>
        </>
    );
}
export default Contact;