import '../../App.css'
import ServicesBody from '../ServicesPage/ServiceBody.js'
import Footer from '../Footer/Footer.js'







function Services(){
    return(
        <>
        <ServicesBody/>
        <Footer/>
        </>
    );
}
export default Services;